import React, { useState, useEffect } from "react"

interface CounterProps {
  targetNumber: number
  duration?: number
}

const LiveCounter: React.FC<CounterProps> = ({ targetNumber, duration = 2000 }) => {
    const [currentNumber, setCurrentNumber] = useState(0)

    useEffect(() => {
        const stepTime = Math.max(Math.floor(duration / targetNumber), 10)
        let current = 0

        const interval = setInterval(() => {
        current += 1
        setCurrentNumber(current)

        if (current >= targetNumber) {
            clearInterval(interval)
        }
        }, stepTime)

        return () => clearInterval(interval)
    }, [targetNumber, duration])

    return <span>{currentNumber}</span>
}

export default LiveCounter
