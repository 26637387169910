//styles
import './layout.scss'

//react
import { FC } from 'react'

//components
import Header from './header/header'
import Footer from './footer/footer'
import FallingRunes from '../fallingRunesBackground/fallingRunesBackground'


type layoutProps = {
    children: JSX.Element | JSX.Element[]
}

const Layout: FC<layoutProps> = ({ children }) => {
    return (
        <div className="layout">
            <Header/>
            {children}
            <FallingRunes/>
            <Footer/>
        </div>
    )
}

export default Layout