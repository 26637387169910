import { useState, useEffect, useRef } from 'react'
import './homeAboutUs.scss'
import AboutUsImage from '../../../assets/home/aboutUs.png'
import Button from '../../general/button/button'
import { useNavigate } from 'react-router-dom'
import TypingEffect from '../../general/typingEffect/typingEffect'
import LiveCounter from '../../general/liveCounter/liveCounter'

const HomeAboutUs = () => {
    const navigate = useNavigate()
    const [isVisible, setIsVisible] = useState(false)
    const sectionRef = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                }
            },
            { threshold: 0.3 } 
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current)
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current)
            }
        }
    }, [sectionRef.current])

    return (
        <div className="home-about-us container" ref={sectionRef}>
            <div className="home-about-us-section">
                <div className="home-about-us-title-container">
                    <p className="subtitle">ABOUT US</p>
                    <h2 className="title">We Create World Class Digital Solutions For All Businesses</h2>
                </div>
                <img src={AboutUsImage} alt="" className='home-about-us-image'/>
                <div className="home-about-us-data-container">
                    <div className="home-about-us-data-item">
                        {isVisible && (
                            <h2>
                                <LiveCounter targetNumber={2} duration={500}/> <TypingEffect text='Weeks' />
                            </h2>
                        )}
                        <p>Average project time</p>
                    </div>
                    <div className="home-about-us-data-item">
                        {isVisible && (
                            <h2>
                                 <LiveCounter targetNumber={6} duration={500}/> <TypingEffect text='Years' />
                            </h2>
                        )}
                        <p>Average experience of team members</p>
                    </div>
                </div>
            </div>
            <div className="home-about-us-section">
                <p className="home-about-us-content">
                    Welcome to Web Olympus, where innovation meets functionality, and your digital presence transforms into a powerful business asset. With a passion for creating stunning, user-centric websites and a commitment to elevating your online visibility, we are your dedicated partner in web redesign, web design and development, and SEO solutions.
                </p>
                <Button text='LEARN MORE' onClick={() => navigate('/contact-us')} />
            </div>
        </div>
    );
}

export default HomeAboutUs
