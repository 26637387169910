//styles
import './emailForm.scss'
import EmailFormImg from '../../../assets/home/emailFormImg.png'

//components
import Button from '../../general/button/button'
import Input from '../../general/input/input'
import Notification from '../../general/notification/notification'

//react
import { useState } from 'react'
import { unauthorizedRequest } from '../../../network/requests'
import { contactUrl } from '../../../network/urls'

const EmailForm = () => {
    const [email, setEmail] = useState('')

    const [notificationData, setNotificationData] = useState<{
        error: boolean
        text: string
        open: boolean
    }>({
        error: false,
        text: '',
        open: false
    })
    const [errors, setErrors] = useState<{
        email?: string
    }>({})

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleSubmit = () => {
        setNotificationData({
            ...notificationData,
            open: false
        })
        setErrors({})

        const newErrors: typeof errors = {}

        if (email.length === 0) {
            newErrors.email = 'Email is required.'
        } else if (!validateEmail(email)) {
            newErrors.email = 'Please enter a valid email address.'
        }

        setErrors(newErrors)

        if (Object.keys(newErrors).length === 0) {
            unauthorizedRequest(contactUrl, 'POST', {
                name: 'unknown',
                email: email,
                message: 'unknown',
                phone_number: 'unknown'
            })
            .then((response) => {
                if(response.result){
                    setNotificationData({
                        error: false,
                        text: 'Thank you for your request! We will contact you back soon!',
                        open: true
                    })

                    setTimeout(() => {
                        setNotificationData({
                            ...notificationData,
                            open: false
                        })
                    }, 5000)
                    setEmail('')
                }else{
                    setNotificationData({
                        error: true,
                        text: 'Something went wrong, please try again later.',
                        open: true
                    })

                    setTimeout(() => {
                        setNotificationData({
                            ...notificationData,
                            open: false
                        })
                    }, 5000)
                }
            })
        }
    }
    
    return (
        <div className="email-form">
                <Notification open={notificationData.open} text={notificationData.text} error={notificationData.error} />
                <div className="email-form-wrapper">
                <div className="email-form-container container">
                    <img src={EmailFormImg} alt="" />
                    <div className="email-form-content">
                        <h2 className="title">We will contact you</h2>
                        <p className="subtitle">Leave your email and we will contact you</p>
                        <div className="email-form-input-container">
                            <Input placeholder='Enter your Email' error={errors.email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} />
                            <Button text='SUBMIT' onClick={() => {
                                handleSubmit()
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailForm