//styles
import './home.scss'

//components
import Layout from "../../components/general/layout/layout"
import Hero from "../../components/home/hero/hero"
import HomeAboutUs from '../../components/home/homeAboutUs/homeAboutUs'
import HomeServices from '../../components/home/services/homeServices'
import HomePortfolio from '../../components/home/homePortfolio/homePortfolio'
import Feedback from '../../components/home/feedback/feedback'
import EmailForm from '../../components/home/emailForm/emailForm'
import ContactUs from '../../components/general/contactUs/contactUs'
import FallingRunes from '../../components/general/fallingRunesBackground/fallingRunesBackground'

const Home = () => {
    return (
        <Layout>
            <Hero/>
            <div className="home-content">
                <HomeAboutUs/>
                <HomeServices/>
                <HomePortfolio/>
                <Feedback />
                <EmailForm />
                <ContactUs />
            </div>
        </Layout>
    )
}

export default Home