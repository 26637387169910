import React, { useRef, useEffect, useState } from "react";

const FallingRunes = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationRef = useRef<number | null>(null);
  const lastFrameTimeRef = useRef(0);
  const runes = ["ᚠ", "ᚢ", "ᚦ", "ᚨ", "ᚱ", "ᚷ", "ᚺ", "ᛖ", "ᛗ", "ᛟ"];
  const FPS_LIMIT = 60; // Можно поставить 30, если ПК слабый
  const particles = useRef<{ x: number; y: number; speed: number; rune: string; size: number; opacity: number; }[]>([]);

  const [size, setSize] = useState(18); 
  const [length, setLength] = useState(50); 
  
  useEffect(() => {
      const updateScale = () => {
          if (window.innerWidth < 768 && window.innerWidth > 430) {
            setSize(15)
            setLength(40)
          } else if (window.innerWidth < 430) {
            setSize(12)
            setLength(30)
          } else {
            setSize(18)
            setLength(50)
          }
      };

      updateScale();
      window.addEventListener('resize', updateScale);
      return () => window.removeEventListener('resize', updateScale);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      particles.current = Array.from({ length: length }).map(() => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        speed: Math.random() * 1 + 0.5,
        rune: runes[Math.floor(Math.random() * runes.length)],
        size: Math.random() * size + 14,
        opacity: Math.random() * 0.3 + 0.7,
      }));
    };

    setCanvasSize();

    const update = (time: number) => {
      if (time - lastFrameTimeRef.current < 1000 / FPS_LIMIT) {
        animationRef.current = requestAnimationFrame(update);
        return;
      }
      lastFrameTimeRef.current = time;

      ctx.clearRect(0, 0, canvas.width, canvas.height); // Очищаем только там, где меняется контент

      particles.current.forEach((particle) => {
        ctx.globalAlpha = particle.opacity;
        ctx.fillStyle = "#9C7339"; // Цвет рун
        ctx.font = `${particle.size}px serif`;
        ctx.fillText(particle.rune, particle.x, particle.y);

        particle.y += particle.speed;

        if (particle.y > canvas.height) {
          particle.y = -20;
          particle.x = Math.random() * canvas.width;
        }
      });

      animationRef.current = requestAnimationFrame(update);
    };

    animationRef.current = requestAnimationFrame(update);

    window.addEventListener("resize", setCanvasSize);

    return () => {
      window.removeEventListener("resize", setCanvasSize);
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
        pointerEvents: "none",
      }}
    />
  );
};

export default FallingRunes;
