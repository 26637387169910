// styles
import './hero.scss'

//components
import Button from '../../general/button/button'
import Model from '../../../assets/home/greekHead/3dObject'
import TypingEffect from '../../general/typingEffect/typingEffect'

//react
import { useNavigate } from 'react-router-dom'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { Suspense, useEffect, useRef } from 'react'


const Hero = () => {
    const navigate = useNavigate()
    return (
        <div className="home-hero hero">
            <div className="hero-3d-object">
                <Canvas style={{ width: '100%', height: '100%', pointerEvents: 'none' }} camera={{ position: [0, 1, 5], fov: 50 }}>
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[2, 5, 2]} />
                    <Suspense fallback={null}>
                        <Model/>
                    </Suspense>
                    <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} makeDefault={true} />
                </Canvas>
            </div>
            <div className="hero-container">
                <h2 className="hero-title"><TypingEffect text='Digital Solution Agency' /></h2>
                <p className="hero-subtitle">
                    Elevate your online presence with cutting-edge design, seamless development, and strategic SEO.
                    Unleash the full potential of your digital journey.
                </p>
                <Button text='GET A QUOTE' onClick={() => navigate('/contact-us')} />
            </div>
        </div>
    );
};

export default Hero;