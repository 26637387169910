//styles
import './homePortfolio.scss'

//components
import Portfolio1 from '../../../assets/home/portfolio1.png'
import Portfolio2 from '../../../assets/home/portfolio2.png'
import Portfolio3 from '../../../assets/home/portfolio3.png'
import Portfolio4 from '../../../assets/home/portfolio4.png'
import Portfolio5 from '../../../assets/home/portfolio5.png'
import Portfolio6 from '../../../assets/home/portfolio6.png'
import Portfolio7 from '../../../assets/home/portfolio7.png'
import Portfolio8 from '../../../assets/home/portfolio8.png'
import Portfolio9 from '../../../assets/home/portfolio9.png'
import Portfolio10 from '../../../assets/home/portfolio10.png'
import Portfolio11 from '../../../assets/home/portfolio11.png'
import Portfolio12 from '../../../assets/home/portfolio12.png'

const HomePortfolio = () => {
    return (
        <div className="home-portfolio" id="portfolio">
            <div className="home-portfolio-header container">
                <div className="home-portfolio-title-container">
                    <p className="subtitle">Portfolio</p>
                    <div className="title">Our Work</div>
                </div>
            </div>
            <div className="home-portfolio-list container">
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://ri-software.com.ua','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio1} />
                    <h2 className="home-portfolio-list-item-title">
                        RI Software
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        We partnered with RI Software to create a modern, user-focused website that effectively showcases their services and expertise. By implementing a sleek design, and seamless navigation, we enhanced their online presence and improved client engagement. Our efforts resulted in a 45% increase in session duration, a 30% boost in lead conversions, and a 50% growth in international traffic, solidifying their reputation as a global leader in custom software solutions.
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://lighttheminds.org','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio2} />
                    <h2 className="home-portfolio-list-item-title">
                        Light The Minds
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        We worked with Light The Minds, a nonprofit dedicated to helping Ukrainian students access educational opportunities in the U.S., to create a compelling and resourceful online platform. By designing a user-friendly website with clear navigation and integrating tools for scholarship applications and resources, we enhanced their ability to connect with students and donors. Our efforts led to a 50% increase in student inquiries, improved visibility among U.S. educational institutions, and strengthened their mission to empower Ukrainian youth through education.
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://www.opendue.com/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio3} />
                    <h2 className="home-portfolio-list-item-title">
                        Due
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Global payment platform due gets Website Service Leader, Liam Terry’s vote on our list of cool designs. Liam thinks “the motion is exemplary. I love the balance of monochrome with the vibrant colours. The due site also hosts some great storytelling on what’s typically a boring subject!”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://wordstone.com/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio4} />
                    <h2 className="home-portfolio-list-item-title">
                        Wordstone
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Wordstone Dispute Resolution is a Parisian (France) based boutique law firm dedicated to international arbitration. The Wordstone website’s “subtle animations as you move through it” really impressed KIJO’s Co-Founder, Jordan Thompson.
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://kieran-clarke.com/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio5} />
                    <h2 className="home-portfolio-list-item-title">
                        Kieran Clarke
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Kieran Clarke is a world championship motorcycle racer turned stuntman. He now specialises in the development of bespoke vehicles that are used for camera tracking applications. Our UI/UX Designer, Danny Findon-Kent likes the “brilliant imagery” on Kieran Clarke’s website. “The use of video is exemplary too. I like the unique layout of the site that’s been coupled with a cohesive website design style throughout too.”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://www.mimcocapital.com/?ref=maxibestof.one','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio6} />
                    <h2 className="home-portfolio-list-item-title">
                        Mimco Capital
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Mimco Capital is an investment company based in Luxembourg. Website Service Leader, Liam Terry’s has also put this one on his cool websites list. “Mimco Capital’s site looks really slick, futuristic, and yes, “cool” for a finance company! It also has a great, visual mega menu. When it drops down, the further links are contained within visual cards, which makes them more engaging to look at. The menu itself is also a slider, so there’s no limit to the amount of links that can be included. It feels incredibly innovative.”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://zelt.app/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio7} />
                    <h2 className="home-portfolio-list-item-title">
                        Zelt
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Zelt is an HR application that integrates directly with a company’s HR software, including hours worked and holidays and makes recording benefits a breeze. KIJO Co-Founder, Jordan Thompson thinks the Zelt website is “very engaging. It’s got a really unique landing page for a software brand – definitely one of those cool websites to draw inspiration from!”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://energy-park.co.uk/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio8} />
                    <h2 className="home-portfolio-list-item-title">
                        Energy Park
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Energy Park provides tailored EV charging solutions for homes, workplaces, residential sites and businesses. Website Service Leader, Liam Terry loves “the bold aesthetics and large typography. It has enormous, eye-catching CTAs and buttons, and very impactful visual media. Energy Park’s is a stand out in modern web design, for sure.”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://www.eventbeds.com/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio9} />
                    <h2 className="home-portfolio-list-item-title">
                        EventBeds
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Website Service Leader, Liam Terry’s next recommendation for inspiration is EventBeds. EventBeds gives event goers access to discounted and convenient accommodation options when attending an event. Liam thinks this is a “great SaaS website (which stands for ‘software as a service’). It explains the features of the application in a really visual and clear way, utilising high-quality motion and expert graphics.”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://shardeum.instawp.xyz/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio10} />
                    <h2 className="home-portfolio-list-item-title">
                        Shardeum
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Shardeum is an EVM-compatible sharded blockchain. Co-Founder, Jordan Thompson acknowledges that the Shardeum’s website “isn’t to everyone’s taste, but I can appreciate the unique design and colour scheme. It certainly stands out and quantifies as a very impactful piece of creative web design work.”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://www.tagheuer.com/gb/en/eyewear/collection-eyewear.html','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio11} />
                    <h2 className="home-portfolio-list-item-title">
                        Tag Heuer
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Tag Heuer is famous for its luxury chronograph watches for men. Our UI/UX Designer, Danny Findon-Kent likes the Tag Heuer site’s “three dimensional scroll animation. It’s engaging, luxurious, but also sporty. I love it when a design choice also captures the essence of a brand. That’s what makes it an example of the best website design; the marrying of these two elements.”
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://www.longines.com/','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio12} />
                    <h2 className="home-portfolio-list-item-title">
                        Longines
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        Staying with watches, Website Service Leader, Liam Terry is impressed with luxury Swiss watchmaker Longines’ website. “Longines hosts a great product promotional microsite which uses expansive video and side scrolling techniques to perfection. It’s confident and really captures your attention with the story. This website is great for design inspiration.”
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomePortfolio