//styles
import './homeServices.scss'

//react
import { useNavigate } from 'react-router-dom'

const arrow = <svg viewBox="0 0 37 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 15.6295C1 14.8241 1.59847 14.1586 2.37496 14.0532L2.59082 14.0387L34.4072 14.0387C35.2858 14.0387 35.9981 14.7509 35.9981 15.6295C35.9981 16.4349 35.3996 17.1005 34.6231 17.2058L34.4072 17.2203L2.59082 17.2203C1.71223 17.2203 1 16.5081 1 15.6295Z" fill="white" stroke="white"/>
    <path d="M20.4542 3.97941C19.8316 3.3595 19.8294 2.35225 20.4494 1.72966C21.0129 1.16367 21.8966 1.11042 22.5204 1.57117L22.6991 1.72481L35.5317 14.5023C36.0994 15.0675 36.1511 15.9544 35.6866 16.5781L35.5318 16.7568L22.6992 29.5364C22.0767 30.1564 21.0694 30.1543 20.4495 29.5317C19.8858 28.9658 19.8363 28.0819 20.2997 27.4601L20.4541 27.282L32.1541 15.6289L20.4542 3.97941Z" fill="white" stroke="white"/>
</svg>


const HomeServices = () => {
    const navigate = useNavigate()

    return (
        <div className="home-services">
            <div className="home-services-title-container">
                <p className="subtitle">What we do</p>
                <h2 className="title">Our core services</h2>
            </div>
            <div className="home-services-list-wrapper">
                <div className="home-services-list container">
                    <div className="home-services-list-item" onClick={() => {
                        navigate('/services#development')
                    }}>
                        <div className="home-services-list-item-content">
                            <p>01.</p>
                            <h2>Development</h2>
                        </div>
                        {arrow}
                    </div>
                    <div className="home-services-list-item" onClick={() => {
                        navigate('/services#maintenance')
                    }}>
                        <div className="home-services-list-item-content">
                            <p>02.</p>
                            <h2>Maintenance and Support</h2>
                        </div>
                        {arrow}
                    </div>
                    <div className="home-services-list-item" onClick={() => {
                        navigate('/services#hosting')
                    }}>
                        <div className="home-services-list-item-content">
                            <p>03.</p>
                            <h2>Web Hosting and Domain Management</h2>
                        </div>
                        {arrow}
                    </div>
                    <div className="home-services-list-item" onClick={() => {
                        navigate('/services#api')
                    }}>
                        <div className="home-services-list-item-content">
                            <p>04.</p>
                            <h2>API Integration and Development</h2>
                        </div>
                        {arrow}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeServices