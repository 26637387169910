import React, { useState, useEffect } from "react"

interface TypingEffectProps {
    text: string
    speed?: number
}

const TypingEffect: React.FC<TypingEffectProps> = ({ text, speed = 50 }) => {
    const [displayedText, setDisplayedText] = useState("")

    useEffect(() => {
        let index = 0
        let typedText = ''
        const interval = setInterval(() => {
            if(index < text.length) {
                typedText = typedText + text[index]
                index++
                
                setDisplayedText(typedText)
            }else{
                clearInterval(interval)
            }
        }, speed)

        return () => clearInterval(interval)
    }, [text, speed])


    return <p>{displayedText}</p>
};

export default TypingEffect
