import React, { useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import ModelURL from '../greekHead/winged_victory_version_2.glb';

export const Model = () => {
    const { scene } = useGLTF(ModelURL);
    const [scale, setScale] = useState(25);
    const [rotationY, setRotationY] = useState(0);
    const [position, setPosition] = useState([0, 0, 0]); 


    useEffect(() => {
        const updateScale = () => {
            if (window.innerWidth < 768 && window.innerWidth > 430) {
                setScale(25)
                setPosition([0, -0.5, 0])
            } else if (window.innerWidth < 430) {
                setScale(20)
                setPosition([0, -0.5, 0])
            } else {
                setScale(20)
                setPosition([0, 0, 0])
            }
        };

        updateScale();
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, []);

    useFrame(() => {
        setRotationY((prev) => prev + 0.01)
    });

    return <primitive object={scene} scale={scale} position={position} rotation={[0.2, rotationY, 0]} />;
};


useGLTF.preload(ModelURL);

export default Model
